import React, { useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Icon from 'ui-kit-v2/icon/icon';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';

import { IconList, Icon as IconListProps } from 'display-components/icon-list/icon-list';
import MedicineCard from 'display-components/medicine-card/medicine-card';

import { MedicinesCardsMock, ModalConditionsIconsMock } from 'util/birdi-select-conditions.mock';
import { convertToTitleCase } from 'util/string';

import BirdiSelectFormularyHealthBadges from '../birdi-select-formulary-health-badge/birdi-select-formulary-health-badge';
import './birdi-select-formulary-modal.styles.scss';

const SEARCH_MIN_LENGTH = 3;

const BirdiSelectFormularyContent = () => {
    const { t } = useTranslation();
    const [iconSelected, setIconSelected] = useState<IconListProps>(ModalConditionsIconsMock[0]);
    const [searchedDrug, setSearchDrug] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    const drugList = MedicinesCardsMock.sort((a, b) => a.name.localeCompare(b.name));

    const filterBySearch = (query: string): any[] => {
        const lowerCaseQuery = query.toLowerCase();
        return drugList.filter((drug) => drug.name.toLowerCase().includes(lowerCaseQuery));
    };

    const filterByIcon = (icon: IconListProps): any[] => {
        const iconSelected = icon.id;
        return drugList?.filter((item, _) => {
            if (iconSelected === 0) return true;
            return item.groupId === iconSelected;
        });
    };

    const filteredDrugList = useMemo(() => {
        if (searchedDrug.length >= SEARCH_MIN_LENGTH) {
            return filterBySearch(searchedDrug);
        }
        return filterByIcon(iconSelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchedDrug, iconSelected, drugList]);

    const handleIconListClick = (icon: IconListProps) => {
        handleClear();
        setIconSelected(icon);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchDrug(e.target.value);
    };

    const handleClear = () => {
        setSearchDrug('');
    };

    const handleKeyUp = (keyboardEvent: React.KeyboardEvent<HTMLInputElement>) => {
        const key = keyboardEvent.code || keyboardEvent.keyCode;
        if (key === 'Enter' || key === 13) {
            inputRef.current?.blur();
        }
    };

    return (
        <div className="birdi-select-formulary-modal">
            <div className="modal__header">
                <span className="header__title">{t('components.birdiSelect.formularyModal.subtitle')}</span>
                <div className="header__iconList">
                    <IconList
                        list={ModalConditionsIconsMock}
                        onChange={(icon) => handleIconListClick(icon)}
                        selectedIcon={iconSelected}
                    />
                </div>
            </div>
            <div className="modal__content">
                <div className="content__divider"></div>

                <div className="content__scrollable">
                    {iconSelected.id === 0 && (
                        <div className="content__filter">
                            <span className="content__title">
                                {t('components.birdiSelect.formularyModal.filter.title')}
                            </span>
                            <form>
                                <TextInputComponent
                                    name="filterByDrugName"
                                    label={t('components.birdiSelect.formularyModal.filter.inputLabel')}
                                    className="content__filter--input"
                                    value={searchedDrug}
                                    onChange={handleChange}
                                    onKeyUp={handleKeyUp}
                                    autoComplete="off"
                                    contentRight={{
                                        children: (
                                            <>
                                                {searchedDrug && searchedDrug.length >= SEARCH_MIN_LENGTH && (
                                                    <div className="content__filter--close">
                                                        <Icon icon="circle-close" onClick={handleClear} />
                                                    </div>
                                                )}
                                            </>
                                        )
                                    }}
                                    ref={inputRef}
                                />
                            </form>
                        </div>
                    )}

                    {iconSelected.id !== 0 && (
                        <div className="content__badge">
                            <BirdiSelectFormularyHealthBadges icon={iconSelected} />
                        </div>
                    )}

                    <div className="content__drugList">
                        {searchedDrug && searchedDrug.length >= SEARCH_MIN_LENGTH && filteredDrugList.length > 0 && (
                            <span className="content__drugList--hint">
                                <Trans
                                    i18nKey="components.birdiSelect.formularyModal.filter.medications"
                                    values={{ searchedDrug: convertToTitleCase(searchedDrug) }}
                                />
                            </span>
                        )}

                        {filteredDrugList.map((item, index) => (
                            <MedicineCard
                                key={index}
                                name={item.name}
                                description={item.description}
                                dose={item.dose}
                                ndc={item.ndc}
                                gpi={item.gpi}
                                variation="list"
                            />
                        ))}

                        {filteredDrugList.length === 0 && searchedDrug && searchedDrug.length >= SEARCH_MIN_LENGTH && (
                            <div className="content__drugList--empty">
                                <Icon icon="small-search" width="64" height="64" viewBox="0 0 64 64" />
                                <div>
                                    <h3>
                                        <Trans
                                            i18nKey="components.birdiSelect.formularyModal.drugNotFound.title"
                                            values={{ searchedDrug: convertToTitleCase(searchedDrug) }}
                                        />
                                    </h3>
                                    <p>{t('components.birdiSelect.formularyModal.drugNotFound.copy')}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BirdiSelectFormularyContent;
